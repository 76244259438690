import React from 'react'
import Layout from '../../components/Layout'
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faFacebook , faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import '../../styles/recipe/recipepage.css'

export default function recepiDish() {
    return (
        <Layout>
            {/* For page head */}
            <Helmet>
                <title>Recipe Dish - maker Recipe</title>
            </Helmet>

            <div className="RecipePageFirstLayer">
                <Container>
                    <Row>
                        <Col md="12" className="Recipe-Title">
                            <h2>Recipe Dish</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="Recipe-Image">
                            <StaticImage 
                                src="../../images/recipepage/dishphoto1.jpg" 
                                alt="Recipe1"
                                width={500}
                                height={500} />
                        </Col>
                        <Col md="6" className="Recipe-Ingredients">
                            <h2>Ingredients</h2>
                            <ul>
                                <li>1 packet Adabi Fish Soup Spices</li>
                                <li>4 tablespoons of Adabi Chicken and Meat Curry Powder</li>
                                <li>2 cups of Dal Beans</li>
                                <li>3 cloves of Garlic (grinded)</li>
                                <li>6 Red Shallots (grinded)</li>
                                <li>2cm Ginger (grinded)</li>
                                <li>2 stalks of Lemongrass</li>
                                <li>2 Potatoes (cut into 4)</li>
                                <li>I Carrot (cut into 1cm length)</li>
                                <li>2 Brinjal (cut into 1cm length)</li>
                                <li>300g Long Beans (cut into 1cm length)</li>
                                <li>2 Tomatoes (cut into 4)</li>
                                <li>250ml Fresh Coconut Milk</li>
                                <li>3 Pandan Leaves</li>
                                <li>2 Coriander Leaves (sliced)</li>
                                <li>4 tablespoons of Cooking Oil</li>
                                <li>3 tablespoons of Ghee</li>
                                <li>Adabi Turmeric Powder</li>
                                <li>Adabi Salt</li>
                                <li>Sugar</li>
                                <li>Water</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Recipe Page second layer */}
            <div className="RecipePageSecondLayer">
                <Container>
                    <Row>
                        <Col md="12" className="RecipeInstructions-Title">
                            <h2>Cooking Instructions</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6" className="RecipeInstructions-Steps">
                            <h4>Spices Mix Ingredients</h4>
                            <ul>
                                <li>3 Shallots (sliced)</li>
                                <li>2 cloves of Garlic (sliced)</li>
                                <li>2cm Ginger (sliced)</li>
                                <li>3 stalks of Curry Leaves</li>
                                <li>Fenugreek Spice (Halba)</li>
                            </ul>

                            <h4>Dal Paste Cooking Instructions</h4>
                            <ul>
                                <li>
                                    Boil the Dal beans together with Adabi Turmeric Powder, Adabi 
                                    Chicken and Meat Curry Powder, pandan leaves and ground spices. 
                                    Mix well and keep stirring until the beans soften.
                                </li>
                                <li>
                                    Add potatoes and carrots. Stir and cook until tender.
                                </li>
                                <li>
                                    Add brinjal, long beans, coconut milk, Adabi Salt and sugar. 
                                    Stir until it boils and put aside for later.
                                </li>
                            </ul>

                            <h4>Spice Mixture Cooking Instructions</h4>
                            <ul>
                                <li>
                                    Heat the pot and pour in cooking oil, ghee, Adabi Fish Soup 
                                    Spices, curry leaves, ginger, onions, garlic and Fenugreek Spice.
                                </li>
                                <li>Sautéuntil golden brown and fragrant.</li>
                                <li>Pour the Dal Paste prepared earlier into the spices mixture pot.</li>
                                <li>Add coriander leaves and tomatoes.</li>
                                <li>Mix well and ready to be served.</li>
                            </ul>
                        </Col>

                        <Col md="6">
                            <Row>
                                <Col md="12" className="ProductUsed-Image">
                                    <StaticImage 
                                        src="../../images/soupProducts/Perencah_Sup_25gm.png" 
                                        alt="RecipeProduct1"
                                        width={300}
                                        height={300} />
                                </Col>
                                <Col md="12" className="ProductUsed-Title">
                                    <p>Product Used:</p>
                                </Col>
                                <Col md="12" className="ProductUsed-Subtitle">
                                    <p>Fish Soup Spices</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <div className="recipeSocialMedia-container">
                            <div className="productFacebookButton recipepageFacebook">
                                <Button>
                                    <a href="https://www.facebook.com/MAKER-Recipe-101510671983618/">
                                        <FontAwesomeIcon icon={faFacebook} className="footerIcon" />
                                        {" "}Facebook
                                    </a>
                                </Button>
                            </div>
                            <div className="productInstagramButton recipepageInstagram">
                                <Button>
                                    <a href="https://www.instagram.com/maker.recipe/?hl=en">
                                        <FontAwesomeIcon icon={faInstagram} className="footerIcon" />
                                        {" "}Instagram
                                    </a>
                                </Button>
                            </div>
                            <div className="productWhatsappButton recipepageWhatsapp">
                                <Button>
                                    <a href="https://www.instagram.com/maker.recipe/?hl=en">
                                        <FontAwesomeIcon icon={faWhatsapp} className="footerIcon" />
                                        {" "}Whatsapp
                                    </a>
                                </Button>
                            </div>
                        </div>
                    </Row>
                </Container>
            </div>
        </Layout>
    )
}
